<template>
    <div class="app-main__inner">
      <div class="row">
          <div class="col-md-12">
              <div class="app-page-title dashboard-title">
                  <div class="page-title-wrapper">
                      <div class="page-title-heading">
                          <div class="page-title-icon">
                              <i class="pe-7s-timer icon-gradient bg-happy-itmeo">
                              </i>
                          </div>
                          <div>Create Zoom Meeting</div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body">
                  <div class="container contact-form">
    <b-form @submit.prevent="onSubmit" @reset="onReset" v-if="show">
    <b-form-group
    label-cols-sm="4"
      label-cols-lg="2"
      class="m-2 mb-4"
       id="meeting-topic-1" label="Meeting Topic:" label-for="meeting-topic">
            <b-form-input
              id="meeting-topic"
              v-model="form.topic"
              placeholder="Meeting topic"
              required
            ></b-form-input>
          </b-form-group>
  <b-form-group
  label-cols-sm="4"
      label-cols-lg="2"
      class="m-2 mb-4"
       id="meetingAgenda-6" label="Meeting Agenda:" label-for="meetingAgenda">
            <b-form-textarea
              id="meetingAgenda"
              rows="2"
              no-resize
              v-model="form.agenda"
              placeholder="Meeting Agenda (Optional)"
            ></b-form-textarea>
          </b-form-group>
  <b-form-group
       label-cols-sm="4"
      label-cols-lg="2"
      class="m-2 mb-4" id="start_time-6" label="Date - Time:" label-for="start_time">
            <datetime
                type="datetime"
                v-model="form.start_time"
                input-class="meethingTime"
                format="yyyy-MM-dd HH:mm:ss"
                :phrases="{ok: 'Continue', cancel: 'Exit'}"
                :hour-step="1"
                :minute-step="5"
                :min-datetime="minDatetime"
                :week-start="7"
                use12-hour
                auto
                id="start_time"
                ></datetime>
        </b-form-group>
  
    <b-form-group label-cols-sm="4"
      label-cols-lg="2"
      class="m-2 mb-4" id="meethingDuration-5" label="Meeting Duration:" label-for="meethingDuration">
            <b-form-input
              id="meethingDuration"
              v-model="form.duration"
              type="number"
              placeholder="Meeting duration"
              required
            ></b-form-input>
          </b-form-group>

<!-- <b-form-group
  label-cols-sm="4"
      label-cols-lg="2"
      class="m-2 mb-4"
       id="timeZone-6" label="Time Zone:" label-for="timeZone">
            <v-select id="timeZone" :options="timeZones" v-model="selectedValue" 
                :searchable="searchable" :labelNotFound="'Not found'"
                :labelSearchPlaceholder="'World wide time zones'"/>
        </b-form-group> -->
<b-form-group
    label-cols-sm="4"
      label-cols-lg="2"
      class="m-2 mb-4"
       id="Passcode-1" label="Passcode:" label-for="Passcode">
            <b-form-input
              id="password"
              v-model="form.password"
              placeholder="Only users who have the invite link or passcode can join the meeting"
              type="password"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
    label-cols-sm="4"
      label-cols-lg="2"
      class="m-2 mb-4" label="Video:">

          <b-form-group
          label-cols-sm="2"
          label-align-sm="left"
          class="mb-0"
          label="Host" v-slot="{ ariaDescribedby }">

        <b-form-radio-group
         class="pt-2"
        id="host_video"
        v-model="form.settings.host_video"
        :options="videoOptions"
        :aria-describedby="ariaDescribedby"
        name="host_video"
        ></b-form-radio-group>
    </b-form-group>
    <b-form-group
          label-cols-sm="2"
          label-align-sm="left"
          class="mb-0"
         label="Participant" v-slot="{ ariaDescribedby }">

        <b-form-radio-group
         class="pt-2"
        id="participant_video"
        v-model="form.settings.participant_video"
        :options="videoOptions"
        :aria-describedby="ariaDescribedby"
        name="participant_video"
        ></b-form-radio-group>
    </b-form-group>
    </b-form-group>

<b-form-group
    label-cols-sm="4"
      label-cols-lg="2"
      class="m-2 mb-4" label="Meeting Options:">

    <b-form-checkbox
          id="ejoin_before_host-7"
          v-model="form.settings.join_before_host"
          name="join_before_host-7"
          value="true" class="mb-3"
          unchecked-value="false" >
          Allow participants to join anytime
        </b-form-checkbox>
        <b-form-checkbox
          id="mute_upon_entry-7"
          v-model="form.settings.mute_upon_entry"
          name="mute_upon_entry-7"
          value="true" class="mb-3"
          unchecked-value="false" >
          Mute participants upon entry
        </b-form-checkbox>
        <b-form-checkbox
          id="auto_recording-7"
          v-model="auto_recording"
          name="auto_recording-7"
          value="true" class="mb-3"
          unchecked-value="false" >
          Automatically record meeting on the local computer
        </b-form-checkbox>
        <b-form-checkbox
          id="emailNotification-7"
          v-model="form.settings.registrants_email_notification"
          name="emailNotification-7"
          value="true" class="mb-3"
          unchecked-value="false" >
          Email notification to registered users
        </b-form-checkbox>
        
</b-form-group>
<div class="row">
        <div class="col-6 text-right">
          <b-button type="submit" variant="primary">{{submitBtn}}</b-button>
        </div>
        <div class="col-6 text-left">
          <b-button v-if="submitBtn=='Submit'" type="reset" variant="outline-danger">Reset</b-button>
          <b-button @click="closePopup" v-if="submitBtn!='Submit'" type="button" variant="outline-danger">Close</b-button>
        </div>
          </div>
    </b-form>
                  </div>
                </div>
            </div>
        </div>
  </div>
    </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
// import moment from "moment-timezone";
import moment from "moment";
// import VSelect from '@alfsnd/vue-bootstrap-select'
import { DateTime as LuxonDateTime } from 'luxon'
export default {
    props:{
      selectedMeeting:{
        required:false,
        type:Object
      },
      closeModal:{
        required:false
      }
    },
  //   components: {
  //   VSelect
  // },
    data() {
      return {
        submitBtn:'Submit',
        form: {
            url:'zoommeeting/createZoomMeeting',
            // Zoom Id is from https://api.zoom.us/v2/users with JWT token from postman
            userId:process.env.VUE_APP_ZOOM_USER_ID,
            topic:'', 
            type:2,// always to for schedule meeting
            timezone:'',
            duration :0,
            start_time:'',
            password:'',
            settings:{
              host_video:false,
              participant_video:false,
              registrants_email_notification:true,
              join_before_host:false,
              mute_upon_entry:false,
              auto_recording:'local'
            },
            coachingUsers:[]
        },
        auto_recording:false, //used for auto recording string return if true it will change auto_recording in settings to local
        videoOptions: [
          { text: 'On', value: true },
          { text: 'Off', value: false }
        ],
        selectedValue: null,
        timeZones:[],
        searchable:true,
        start_time:'',
        minDatetime:LuxonDateTime.local().minus({ days: 0 }).toISO(),
        meethingType:[
            { text: 'Select meeting type', value: 0 },
            { text: 'Instant meeting', value: 1 },
            { text: 'Scheduled meeting', value: 2 },
            { text: 'Recurring meeting with no fixed time', value: 3 },
            { text: 'Recurring meeting with fixed time', value: 8 }
        ],
        show: true
      }
    },
    methods: {
      ...mapActions({
            postZoomQuery:'zoommeeting/postZoomQuery',
            fetchUsersData:'auth/fetchGraphData',
        }),
        ...mapGetters({
            GetUsersData:'auth/getPracticeNumber',
            getMeetings:'zoommeeting/getMeetings',
        }),
      closePopup(){
        return this.closeModal()
      },
      onSubmit() {
        this.form.settings.auto_recording=(this.auto_recording)?'local':'none';
        this.form.timezone="Australia/Sydney"//this.selectedValue.value
        this.form.start_time=this.form.start_time.replace('.000','');
        if(this.submitBtn=='Submit'){
            this.form.url='zoommeeting/createZoomMeeting'
            this.postZoomQuery(this.form).then(()=>{
            const response=this.getMeetings()
            ////debugger
            //console.log(response)
            if(response.success==1){
              var responseResult=JSON.parse(response.result)
              const startTime=this.changeDate(responseResult.start_time,'fullDate')
              var param={
                 start_time: startTime,
                  join_url: responseResult.join_url,
                  timezone: responseResult.timezone,
                  url:'users/getCoachingUsers'
              }
              //console.log(param)
              this.fetchUsers(param)
              this.onReset()
              return this.$alert('Meeting created successfuly','Created','success')
            }
            else this.$alert('Issue on meeting creation please contact IT Team','Faild','error')
            })
        }
        else if(this.submitBtn=='Update'){
            this.form.url='zoommeeting/updateMeetingById'
            this.postZoomQuery(this.form).then(()=>{
            const response=this.getMeetings()
            //console.log(response);
            if(response.success==1){
              this.$bvModal.hide('updateMeeting')
              return this.$alert('Meeting updated successfuly','Updated','success')
            }
            else this.$alert('Issue on meeting creation please contact IT Team','Faild','error')
            })
        }
        
      },
      changeDate(date, dateType) {
          const date1 = new Date(date);
          if (dateType === "fullDate") {
            return moment(date1).format("ddd, MMMM Do YYYY HH:mm");
          } else {
            return moment(date1).format("HH:mm");
          }
        },
      onReset() {
        //event.preventDefault()
        // Reset our form values
        this.form= {
            topic:'', 
            type:2,// always to for schedule meeting
            timezone:'',
            duration :0,
            start_time:'',
            password:'',
            settings:{
              host_video:false,
              participant_video:false,
              registrants_email_notification:true,
              join_before_host:false,
              mute_upon_entry:false,
              auto_recording:'local'
            }
        }
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      },
       findUserTable(data) {
        const cashKeys = {
            "Etc/GMT+12": "International Date Line West",
            "Pacific/Midway": "Midway Island, Samoa",
            "Pacific/Honolulu": "Hawaii",
            "America/Juneau": "Alaska",
            "America/Dawson": "Pacific Time (US and Canada); Tijuana",
            "America/Boise": "Mountain Time (US and Canada)",
            "America/Chihuahua": "Chihuahua, La Paz, Mazatlan",
            "America/Phoenix": "Arizona",
            "America/Chicago": "Central Time (US and Canada)",
            "America/Regina": "Saskatchewan",
            "America/Mexico_City": "Guadalajara, Mexico City, Monterrey",
            "America/Belize": "Central America",
            "America/Detroit": "Eastern Time (US and Canada)",
            "America/Indiana/Indianapolis": "Indiana (East)",
            "America/Bogota": "Bogota, Lima, Quito",
            "America/Glace_Bay": "Atlantic Time (Canada)",
            "America/Caracas": "Caracas, La Paz",
            "America/Santiago": "Santiago",
            "America/St_Johns": "Newfoundland and Labrador",
            "America/Sao_Paulo": "Brasilia",
            "America/Argentina/Buenos_Aires": "Buenos Aires, Georgetown",
            "America/Godthab": "Greenland",
            "Etc/GMT+2": "Mid-Atlantic",
            "Atlantic/Azores": "Azores",
            "Atlantic/Cape_Verde": "Cape Verde Islands",
            "GMT": "Dublin, Edinburgh, Lisbon, London",
            "Africa/Casablanca": "Casablanca, Monrovia",
            "Atlantic/Canary": "Canary Islands",
            "Europe/Belgrade": "Belgrade, Bratislava, Budapest, Ljubljana, Prague",
            "Europe/Sarajevo": "Sarajevo, Skopje, Warsaw, Zagreb",
            "Europe/Brussels": "Brussels, Copenhagen, Madrid, Paris",
            "Europe/Amsterdam": "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
            "Africa/Algiers": "West Central Africa",
            "Europe/Bucharest": "Bucharest",
            "Africa/Cairo": "Cairo",
            "Europe/Helsinki": "Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
            "Europe/Athens": "Athens, Istanbul, Minsk",
            "Asia/Jerusalem": "Jerusalem",
            "Africa/Harare": "Harare, Pretoria",
            "Europe/Moscow": "Moscow, St. Petersburg, Volgograd",
            "Asia/Kuwait": "Kuwait, Riyadh",
            "Africa/Nairobi": "Nairobi",
            "Asia/Baghdad": "Baghdad",
            "Asia/Tehran": "Tehran",
            "Asia/Dubai": "Abu Dhabi, Muscat",
            "Asia/Baku": "Baku, Tbilisi, Yerevan",
            "Asia/Kabul": "Kabul",
            "Asia/Yekaterinburg": "Ekaterinburg",
            "Asia/Karachi": "Islamabad, Karachi, Tashkent",
            "Asia/Kolkata": "Chennai, Kolkata, Mumbai, New Delhi",
            "Asia/Kathmandu": "Kathmandu",
            "Asia/Dhaka": "Astana, Dhaka",
            "Asia/Colombo": "Sri Jayawardenepura",
            "Asia/Almaty": "Almaty, Novosibirsk",
            "Asia/Rangoon": "Yangon Rangoon",
            "Asia/Bangkok": "Bangkok, Hanoi, Jakarta",
            "Asia/Krasnoyarsk": "Krasnoyarsk",
            "Asia/Shanghai": "Beijing, Chongqing, Hong Kong SAR, Urumqi",
            "Asia/Kuala_Lumpur": "Kuala Lumpur, Singapore",
            "Asia/Taipei": "Taipei",
            "Australia/Perth": "Perth",
            "Asia/Irkutsk": "Irkutsk, Ulaanbaatar",
            "Asia/Seoul": "Seoul",
            "Asia/Tokyo": "Osaka, Sapporo, Tokyo",
            "Asia/Yakutsk": "Yakutsk",
            "Australia/Darwin": "Darwin",
            "Australia/Adelaide": "Adelaide",
            "Australia/Sydney": "Canberra, Melbourne, Sydney",
            "Australia/Brisbane": "Brisbane",
            "Australia/Hobart": "Hobart",
            "Asia/Vladivostok": "Vladivostok",
            "Pacific/Guam": "Guam, Port Moresby",
            "Asia/Magadan": "Magadan, Solomon Islands, New Caledonia",
            "Pacific/Fiji": "Fiji Islands, Kamchatka, Marshall Islands",
            "Pacific/Auckland": "Auckland, Wellington",
            "Pacific/Tongatapu": "Nuku'alofa"
        };
        return Object.entries(cashKeys).find(([key,val]) => {
            ////console.log(key)
             if(data === key) return val
            });
      },
      fetchUsers(param){
       this.postZoomQuery(param).then(()=>{
          const response=this.GetUsersData()
          this.form.coachingUsers=response.user
          //console.log(response)
      });
      }
    },
    beforeMount(){
        // const guess=moment.tz.guess().split('/')[1];
        // var vm=this
        // this.timeZones = moment.tz.names()
        //     .reduce((memo, tz) => {
        //         memo.push({
        //         name: tz,
        //         offset: moment.tz(tz).utcOffset()
        //         });
                
        //         return memo;
        //     }, [])
        //     .sort((a, b) => {
        //         return a.offset - b.offset
        //     })
        //     .reduce((memo, tz) => {
        //         const timezone = tz.offset ? moment.tz(tz.name).format('Z') : '';
        //         var name=vm.findUserTable(tz.name);
        //         if(name!=undefined){
        //             var text="(GMT"+ timezone+ ") "+ name[1];
        //             var value=name[0]
        //             memo.push({value,text});
        //         }
        //          return memo
        //     }, []);
        //     this.selectedValue=this.timeZones.filter((item)=>{
        //         return item.value.includes(guess)
        //     })[0]
        //     //console.log(this.selectedValue)
        if(this.selectedMeeting!=null){
          this.form=this.selectedMeeting
          this.auto_recording=(this.form.settings.auto_recording=='local')?true:false;
          this.submitBtn="Update"
        }
            ////console.log(selectorOptions)
        // for (var i = 0; i < timezone.length; i++) {
        //     $('select').append('<option value="' + timezone[i] + '">' + timezone[i] + '</option>');
        // }
        //$('select').selectpicker();
    }
  }
</script>
<style>
.meethingTime,.v-select .v-select-toggle{
    width: 100%!important;
    height: calc(2.25rem + 2px)!important;
    padding: .375rem .75rem!important;
    font-size: 1.1rem!important;
    font-weight: 400!important;
    line-height: 1.5!important;
    color: #495057!important;
    background-color: #fff!important;
    background-clip: padding-box!important;
    border: 1px solid #ced4da!important;
    border-radius: .25rem!important;
}
.v-select ul{
  height: 250px!important;
  overflow-y: auto;
}
</style>